* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
    'Lucida Sans', Arial, sans-serif;
}

body {
  background: linear-gradient(
    90deg,
    rgba(48, 16, 255, 1) 0%,
    rgba(100, 115, 255, 1) 100%
  );
}

.header-section {
  text-align: center;
  /* display: flex; */
  flex-direction: column;
  justify-content: start;
  min-width: 520px;
  /* min-height: 600px; */
  /* background: #161a2b; */
  margin: 128px auto;
  border-radius: 100px;
  padding-bottom: 32px;
  margin: 50px;
}

h1 {
  margin: 32px 0;
  color: #fff;
  font-size: 24px;
}

.form_playlistUrl {
  margin-bottom: 10px;
}

.button_playlistUrl {
  padding: 16px;
  border: none;
  border-radius: 0 4px 4px 0;
  cursor: pointer;
  outline: none;
  background: linear-gradient(
    90deg,
    rgba(93, 12, 255, 1) 0%,
    rgba(155, 0, 250, 1) 100%
  );
  color: #fff;
  text-transform: capitalize;
}

/* .button_playlistUrl.edit {
  background: linear-gradient(
    90deg,
    rgba(20, 159, 255, 1) 0%,
    rgba(17, 122, 255, 1) 100%
  );
  padding: 16px 22px;
} */

.video-section {
  text-align: center;
  /* display: flex; */
  flex-direction: column;
  justify-content: start;
  min-width: 520px;
  min-height: 600px;
  background: #161a2b;
  margin: 128px auto;
  border-radius: 100px;
  padding-bottom: 32px;
  margin: 50px;
}

.input_playlistUrl {
  text-align: center;
  display: inline-block;
  padding: 14px 32px 14px 16px;
  border-radius: 4px 0 0 4px;
  /* border: 10px solid #5d0cff; */
  outline: none;
  width: 400px;
  /* background: transparent; */
  background: linear-gradient(
    90deg,
    rgba(93, 12, 255, 1) 0%,
    rgba(155, 0, 250, 1) 100%
  );
  color: #fff;
}

.input_playlistUrl::placeholder {
  color: #e2e2e2;
}

.input_playlistUrl.edit {
  border: 2px solid #149fff;
}

.grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(360px, 1fr)); 
  grid-gap: 48px;
  /* grid-template-columns: repeat(4, 1fr);
  grid-gap: 2px; */
}

.item {
  text-align: center;
}

.caption {
  /* text-align: center; */
  width: 300px;
  background-color: #d5eef5;
  /* border: 2px solid #5d0cff; */
  border-radius: 10px;
  display: inline-block;
} 
